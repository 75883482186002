.no-padding-6{
    padding: 0!important;
}

.container-input{
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

}

.no-padding-row{
    margin: 0!important;
}

.padding-1-rem{
    padding-left: 1rem!important;
}

.chips-container{
    display: 'flex';
    flex-wrap: 'wrap';
}

.chip{
    margin: 2;
}

.select-margin{
    margin-top: 30px;
}


.MuiSelect-select.MuiSelect-select{
    padding-right: 0!important;
}
.report-buttons-container{
    padding-left: 0!important;
   
}

.report-button{
    margin-top: 0.5rem !important;
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    margin-left: 0.5rem;
}
#first-button-report{
    margin-left: 0!important;
}