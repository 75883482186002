.navbar-nav{
    width: 100%;
    
    justify-content: space-between;

    &-links{
        display: flex;
        flex-direction: row!important;
    }
    &-controls{
        display: flex;
        align-items: center;
    }
}

#navBarPorfile{
    height: 4rem;
    background-color: white;
} 
p .margin{
    margin: auto;
}
#nav-noflex{
    display: inline;
    margin: 0.5rem;
    font-family: "Muli-Regular";
    
}
.profile-name{
    size: 14px;
    margin-top: 4px;
    margin-bottom: 4px;
    a {
        color: black;
        text-decoration: none;
    }
}


.profile-img{
    object-fit: cover;
    width: 32px;
    height: 32px;
    margin-right: 0.5rem;
    border-radius: 50%;
}