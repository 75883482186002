.credentials-article{
    background-image: url("./../../../assets/img/fondo-header-no-transparency.png");
    background-color: lightblue;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.credentials-title {
    color: black;
    font-family: "Muli-Regular";
    font-size: 34px;
    display: flex;
    justify-content: center;
    margin-top: 2.125rem;
    margin-bottom: 7rem;
}

.credentials-invalid-description {
    color: black;
    font-family: "Muli-Regular";
    font-size: 60px;
    text-align: center;
    display: flex;
    // justify-content: center;
    align-self: center;
}

.welcome-description{
    font-size: 20px;
    font-family: "Muli-Regular";
    text-align: center;
    margin-top: 4.625rem;
    margin-left: 2.688rem;
    margin-right: 2.688rem;
    margin-bottom: 3.625rem;
    color: black;
}

.store-buttons{
    // display: flex;
    // justify-content: center;
    margin-left: 2.688rem;
    margin-right: 3.813rem;
}

.credentials-loading-spinner{
    width: 100%;
}


.credentials-submitted-description{
    font-size: 20px;
    font-family: "Muli-Regular";
    text-align: center;
    color: black;
}


.credentials-body{
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 6px #00000029;
    width: 36.375rem;
    height: 34.375rem;
}

.invalid-link-body { 
    display: flex;
    align-items: center;
}

.credentials-button{
    width: 16.25rem;
    margin-top: 7.5rem !important;
}

.kuruah-logo{
    position: absolute;
    top: 1.982rem;
    left: 8.75rem;
}