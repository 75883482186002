.tab-style{
    margin:0px;
    font-family: "Catamaran-Regular";
}
.root{
    flex-grow: 1;
}

.tabpanel-margins{
    margin: 0;
    padding: 0;
}

.MuiPaper-root {
    margin-bottom: 1.25rem;
  box-shadow: 0px 0px 0px 0px!important;
  font-family: "Catamaran-Regular"!important;
}

.MuiTab-root{
    
}