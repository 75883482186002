

form {
    font-family: 'Muli-Light'!important;
    input{
        font-family: 'Muli-Light'!important;
    }

    .row{
        margin-right: 0px;
        margin-left: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .col-6{
        padding-left: 7px;
        padding-right: 7px;
    }

    .col-12{
        padding-right: 0px;
    }

    .MuiInputBase-input, .MuiFormLabel-root, .MuiInputLabel-root, .MuiFormControlLabel-label {
        font-family: 'Muli-Light'!important;
        font-size: 16px;
    }

    .description-container{
        // width: 95% !important;
    }

    input:focus {
        border-color: #78065D !important;
        
    }

    .btn-primary{
        background-color:#78065D;
        border-color: #78065D;
    }
    
    .btn-primary:hover{
        background-color: #510342;
        border-color: #510342;
        color: white;
    }

    .btn-outline-primary{
        border-color:#78065D;
        color: #78065D;
    }

    .btn-outline-primary:hover{
        border-color:#510342;
        background-color: #510342;;
        color: white;
    }

    .form-label {
        color:#9D9D9D;
    }

    img.profile-picture{
        border-radius: 50%;
        object-fit: cover;
        width: 120px !important;
        height: 120px !important;
    }

    img.icon {
        width: 15px;
        height: 15px;
        color: white !important;
    }

    .icon-container{
        background-color: #FCA235;
        border-radius: 50%;
        width: 25px !important;
        height: 25px !important;
        //position: absolute;
        align-self: flex-end;
        // justify-self: flex-end;
        left: 60%;
    }

    .worker-form-select{
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border-width: 1px;
        border-color: #9D9D9D !important;
    }
    
    .worker-form-select:focus{
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        // border-color: #9D9D9D !important;
        outline-color: #78065D !important;
        -moz-outline-radius: 5px;
    }

    TextField{
        width: 95% !important;
    }

    
    .form-label{
        font-size: 12px !important;
    }
}

.section-in-demo{
    margin-left: 15rem;
    margin-right: 15rem;
}
.mesagge-attention{
    color: red;
}