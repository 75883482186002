* {
    box-sizing: border-box;
}
.menu-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-trigger {
    background: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    margin-left: auto; /* Strictly for positioning */
}

.menu-trigger:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 10px;
}

.menu-trigger img {
    border-radius: 90px;
}

.menu {
    background: #ffffff;
    border-radius: 8px;
    right: 21px;
    bottom: 16px;
    width: 300px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.decoration-button{
margin:0px 0px 5px 5px;
    color: #78065D!important;
    text-decoration: none!important;
}
.center-text{
    display: grid;
    place-items: center;
}
.menu.active {
    opacity: 1;
    visibility: visible;
    z-index: 999;
    position: fixed !important;
    transform: translate(-25px,-30px);

}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu li {
    border-bottom: 1px solid #dddddd;
}

.menu li a {
    text-decoration: none;
    color: #333333;
    padding: 15px 20px;
    display: block;
}
