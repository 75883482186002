

form {
    input:focus {
        border-color: #78065D !important;
        
    }

    

    .btn-primary{
        background-color:#78065D;
        border-color: #78065D;
    }
    
    .btn-primary:hover{
        background-color: #510342;
        border-color: #510342;
        color: white;
    }

    .btn-primary:active{
        background-color: #510342;
        border-color: #510342;
        color: white;
    }

    .btn-outline-primary{
        border-color:#78065D;
        color: #78065D;
    }

    .btn-outline-primary:hover{
        border-color:#510342;
        background-color: #510342;;
        color: white;
    }

    .btn-outline-primary:active{
        border-color:#510342;
        background-color: #510342;;
        color: white;
    }

    .form-label {
        color:#9D9D9D;
    }

    img.profile-picture{
        border-radius: 50%;
        width: 120px !important;
        height: 120px !important;
    }

    img.icon {
        width: 15px;
        height: 15px;
        color: white !important;
    }

    .icon-container{
        background-color: #FCA235;
        border-radius: 50%;
        width: 25px !important;
        height: 25px !important;
        //position: absolute !important;
        align-self: flex-end;
        // justify-self: flex-end;
        left: 60%;
    }

    

    .worker-form-select{
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border-width: 1px;
        border-color: #9D9D9D !important;
    }
    
    .worker-form-select:focus{
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        // border-color: #9D9D9D !important;
        outline-color: #78065D !important;
        -moz-outline-radius: 5px;
    }
}

.spinner-primary{
    color: #78065D;
}

.modal-title{
    font-size: 1.25rem;
    color:#8B0067;
}