.navbar-demo {
  height: 6rem;
  background-color: #7b085a;
  box-shadow: 0px 6px 6px #00000033;
  opacity: 1;
  margin-bottom: 1.5rem;
}
.kuruah-Logo {
  width: 16rem;
  height: 3rem;
  margin-left: 8rem;
}

.btn-outline-principal{
    border-color:#78065D;
    background-color: #78065D;
    color: white;
}

.btn-outline-principal:hover{
    background-color: white;
    border-color:#78065D;
    color: #78065D;
}

.btn-outline-principal:active{
    background-color: white;
    border-color:#78065D;
    color: #78065D;
}


.btn-outline-primary{
    border-color:#78065D;
    color: #78065D;
}

.btn-outline-primary:hover{
    border-color:#510342;
    background-color: #510342;;
    color: white;
}

.btn-outline-primary:active{
    border-color:#510342;
    background-color: #510342;;
    color: white;
}

.img-logo{
    width: 8.5rem;
    height: 8.5rem;
}

.grid-center{
    display: grid;
    justify-items: center;
}

.content-size{
    max-width: 567px;
}

.container-margin{
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.text-field-margin{
    margin-top: 1rem!important;
    margin-bottom: 1rem!important;
}

.grid-end{
    display: grid;
    justify-items: end;
}

.ready-size{
    max-width: 35rem;
}

.info-margin{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ready-to-start-container-size{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    max-width: 78rem;
}
.font-color{
    color:#78065D;
}

#all-ready{
    font-size: 60px;
}

.log-outbutton-fix{
 position: sticky;
}