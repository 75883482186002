.img-card-information {
  width: 8.5rem;
  height: 8.5rem;
}

.information-container {
  margin-left: 1.75rem;
  h5 {
    font-size: 1.5rem;
  }

  h2{
      font-size: 3.25rem;
      font-family: "Muli-Light";
  }
}
