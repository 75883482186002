.card-container {
  width: 525px;
  height: auto;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  margin: 8px;
  padding: 8px;
  color: #7b085a;
  display: flex;
  justify-content: space-between; 
  font-family: "Catamaran-Regular";

  p{
    margin: 0!important;
  }
  h5{
    margin: 0!important;
  }
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
}

.card-container:hover {
  background-color: #7b085a;
  color: white !important;
  .especial{
    color: #FCA235;
  }
}

.icon-providers {
  height: 20px;
}

.card-active {
  background-color: #7b085a;
  color: white !important;
  .especial{
    color: #FCA235;
  }
}
.arrow-center {
  display: grid;
  place-items: center;
}

.title-provider{
  max-height:22px ;
  overflow-y: hidden;
}


