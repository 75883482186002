.card-img-top.round.product {
    border-radius: 50%;
    width: 7.5rem;
    height: 7.5rem;
    margin-top: 1.25rem;
}

div.card.product {
    width: 13.125rem;
    height: 14.563rem;
    margin: 0.625rem;
    border-radius: 0.75rem;
    box-shadow: 1px 1px 6px 0 #c1c1c1;
}

.card-title.product {
    font-size: 1rem;
}

.card-text.product-price {
    font-size: 0.75rem;
}

.product-card-icon {
    margin-left: 0.25rem;
    display: inline;
}

.info-card-down {
    place-items: center;
}
.icon-row{
    display: grid;
    place-items: end;
    padding-right: 8px;
}