.chart-size{
    height: 700px;
}

.autocomplete-size{
    margin: 8px!important;
}

.chip-margin{
    margin: 2px;
}