.array-root{
    display:flex;
    justify-content: left;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0.25rem;
    margin: 0;
    overflow:auto;
    width: 50%;
    
} 

.size-tab{
    margin-top: 8px ;
    max-width: 78vmax;
    }
.MuiTabs-scroller.MuiTabs-scrollable{
    margin-top: 8px;
}

.Mui-selected {
    outline: 'none',                                                                   
  }