.forcer div {
    height: 260px;
    width: 100%;
}

.forcer div div {
    // background-color: pink !important;   
    // padding-right: 10%;
    // height: 260px !important;
    width: 60%;  
    // padding-bottom: 20%;
    // visibility: visible;
}