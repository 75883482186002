.color-background{
  background-color: black;
  height: 89vh;
}

.suspension-container{
  padding-top: 3rem;
  padding-left: 8rem;
  width: 100%;
  display: flex;
  justify-content: center;

}

.title-size{
  font-size: 2.75rem;

}
.center-button{


}
.yellow-font{
  color : #FCA235;
}
.red-font{
  color : #FF003A;
}
.paragraph-font{
  margin-top: 1rem;
  color:white;
  font-size: 1.5rem;
}
#nav-suspension{
  margin:0;
}