.principal-section-login {
    height: 100vh;
    margin: 0!important;
}

.loginpanel {
    width: auto;
}

.white-section {
    background-color: white;
}

.purple-section {
    background-size: cover;
    background-position: center;
    background-image: url("../../assets/img/fondo-header@2x.png");
}

.login-title {
    font-family: "Muli-Light";
    margin-bottom: 2rem;
}

form {
    .full-input-field {
        width: 100%;
        margin-bottom: 2rem;
        font-family: "Catamaran-Light";
    }
}

.loginButton {
    
}

.login-content-container {
    margin-left: 9rem!important;
    margin-right: 9rem!important;
    padding: 2rem!important;
}

.login-options {
    width: 16rem;
    margin-top: 2rem!important;
    margin-left: auto!important;
    margin-right: auto!important;
    text-align: center;
}

.prurple-link {
    color: #78065D;
}

.no-underline {
    text-decoration: none;
}

.space-link {
    margin-top: 0.75rem!important;
}
#kuruah-logo{
    width: 25rem;
    height: auto;
}