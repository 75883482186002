.color-header{

    color:#78065D;
}

.row-img{
    width:2.5rem ;
    height: 2.5rem;
    border-radius: 50%;
    margin-left: auto;
}
.MuiTableCell-head{
    font-family: "Muli-Regular"!important;
    color: #78065d !important;;
}
.MuiTableCell-body{
    font-family: "Muli-Regular"!important;
}
