.aside-section{
    margin-right: 0.75rem!important;
}

#main-section{
    margin-right: 0.75rem!important;
    width: auto;
    height: auto;
}

form{
    .input-margin{
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }

}

.padding-section{
    padding: 0.75rem;
}

#relatedFranchise{
    padding-left: 0!important;
}
.grid-center{
    display: grid;
    place-items: center;
    justify-content: end;
}