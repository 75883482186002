//Varaibables globales
$principal-color: #78065d;
.vertical_div_menu {
    background-color: $principal-color;
    position: fixed;
    left: 0;
    top: 0;
    right: auto;
    bottom: 0;
    z-index: 10;
    display: block;
    overflow: hidden;
    height: 100vh;
    max-height: 200%;
    min-height: 120%;
    min-width: 85px;
    max-width: 256px;
    float: left;
}

#wrapper {
    overflow-x: hidden;
    display: block;
    position: sticky;
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
}

.menu_container {
    margin-top: 0rem;
    margin-left: 0.75rem;
    padding-right: 1vh;
}

.img_logo {
    width: 170px;
    height: auto;
    margin-top: 8px;
    margin-bottom: 40px;
    margin-left: 1rem;
}

.link-nav-item {
    display: flex;
    font-family: "Muli-Light";
    font-size: 14px;
    color: white;
    text-decoration: none;
    margin-top: 16px;
    margin-bottom: 16px;
    border-left: 3px solid $principal-color;
}

.link-nav-item:hover {
    border-left: 3px solid white;
    color: white
}

.titulo-sec-nav-bar {
    font-family: "Catamaran-Light";
    color: white;
    margin-left: 1rem;
    font-size: 12px;
}

.menu-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: 8px;
}

#logout-div {
    margin-top: 90%;
}

@media (min-width: 768px) {
    .sidebar {
        width: 14rem !important;
    }
}

.vertical-link{
    color: white;
    text-decoration: none;
}
.vertical-link:hover{
    color: white;
}