.form-label {
    color:#9D9D9D;
}

.worker-form-select{
    display: block;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #9D9D9D !important;
}

.worker-form-select:focus{
    display: block;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    // border-color: #9D9D9D !important;
    outline-color: #78065D !important;
    -moz-outline-radius: 5px;
}
.table-icon{
    height: 24px;
    width: 24px;
    margin-left: 6px;
    margin-right: 6px;
    cursor: pointer;
}
.import-container{
    
}
.no-linkunderline{
    text-decoration: none!important;
    color: black;
    font-family: "Muli-Regular";
}

.inventory-success-message{
    color: rgb(241, 241, 241);

    
    border: 2px dashed #1FA245;

}