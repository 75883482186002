.btn-style {
    position: relative;
    left: 89%;
    top: 0%;
    margin: 0;
}

.input-style {
    width: 100%;
    background: #F2F1F9;
    background-color: #FFFFFF;
    border: 1rem;
    padding: 10px;
    position: relative;
    display: inline-block;
    font-family: "Muli-regular"!important;

}
#search-bar{
    font-family: "Muli-Light"!important;
}


