$principal-color: #78065d;

#name-container {
  margin-top: 1.5rem;
  padding-left: 12px;
  h1 {
    color: $principal-color;
  }
}
main section {
  .article-container {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 6px #00000029;
  }
  a .link-container-item {
    display: flex;
    font-family: "Muli-Light";
    color: black;
    text-decoration: none;
    margin-top: 8px;
    margin-bottom: 8px;
    border-left: 3px solid white;
    font-size: 1.25rem;
  }

  a .link-container-item:hover {
    border-left: 3px solid $principal-color;
  }
  #fast-link {
    color: $principal-color;
    font-size: 1.70rem;
    margin-left: 12px;
  }

  #recent-sales {
    color: $principal-color;
  }

  #link-container {
    padding: 24px;
    height: 100%;
    margin-bottom: 0;
  }
  .sales-container{
      width: auto;
  }
  #info-part2-1{
    width: 100%;
  }

  #try-for-help{
    margin-left: 12px;
  }
}


.scroll-overflow {
  overflow-y: scroll;
  max-height: 24rem;
  overflow-x:visible;
}

.margin-boxes {
  margin: 12px;
  padding: 24px;
  display: block;
} 


.no-padding-try{
  padding: 0!important;
  margin-top: 0!important;
  margin-right: 0!important;
}
.chart-container{
  padding: 24px;
  padding-bottom: 0!important;
  margin-bottom: 12px;
  position: relative;
  height: 100%;
}
#today-sale-value{
  margin-top: 0;
}

.crown-img{
  width: 6.25rem;
  height: 6.25rem;
}
.best_fran_name{
  font-size: 24px;
  margin-bottom: 0!important;
}
.best_fran_sales{
  font-size: 32px;
  margin-bottom: 0!important;
  color: $principal-color;
}

.remove-org-padding{
  padding: 0!important;
}