.transformarCarta {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: 0.3s;
}

.franchise-card {
    width: 320px;
    margin: 10px 10px 10px 0;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.card-img-top {
    height: 280px;
    object-fit: cover;
}

.card-body {
    max-height: 85px;
    padding: 16px !important;
    font-family: "Muli-Light";
}

.card-body {
    color: #78065D;
}

.franchise_type_name {
    font-size: 14px;
}

.franchise_name_title {
    font-size: 20px;
}