.chip-margin{
    margin: 0.25rem;
    list-style-type: none;


}

::marker {
   display: none;
   font-size: 0px;
  }

  .Mui-selected {
    outline: 'none',                                                                   
  }