@import url("https://cdn.syncfusion.com/ej2/material.css");
$kuruah-principal-color: #78065d;
@font-face {
    font-family: "Muli-Light";
    src: url("./assets/fonts/muli/Muli-Light.ttf");
}

@font-face {
    font-family: "Muli-Regular";
    src: url("./assets/fonts/muli/Muli.ttf");
}

@font-face {
    font-family: "Catamaran-Light";
    src: url("./assets/fonts/Catamaran/static/Catamaran-ExtraLight.ttf");
}

@font-face {
    font-family: "Catamaran-Medium";
    src: url("./assets/fonts/Catamaran/static/Catamaran-Medium.ttf");
}
@font-face {
    font-family: "Catamaran-Regular";
    src: url("./assets/fonts/Catamaran/static/Catamaran-Regular.ttf");
}

body {
    margin: 0;
    font-family: "Muli-Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

image {
    object-fit: cover;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.body-padding {
    padding-top: 83px;
    padding-left: 256px;
}

.section-body {
    background-color: white;
    margin: 1.5rem!important;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 6px #00000029;
    ;
}

.section-2body{
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 6px #00000029;
    margin: 1.375rem 12px 0px 12px;
    
}

.section-title {
    color: #78065D;
    font-family: "Muli-Regular" !important;
    font-size: 20px !important;
}

.sub-section-title {
    color: #78065D;
    font-family: "Muli-Regular" !important;
    font-size: 16px !important;
    margin: 0!important;
    
}
.nav-item {
    margin-right: 00.5rem;
    button {
        padding: 0 !important;
    }
     
}

input {
    color: -internal-light-dark(#78065D, white);
    font-family: "Muli-Regular";
}

.add-button {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX( 0deg) rotateY( 0deg) rotateZ( 0deg) skew( 0deg, 0deg);
    transform-style: preserve-3d;
    align-items: center;
    border-radius: 50%;
    background-color: $kuruah-principal-color;
    color: #f3f3f3;
    width: 40px;
    height: 40px;
}

.center-continer {
    display: grid;
    place-items: center;
}

button:not(.fab){
    border-radius: 10px !important;
    font-family: "Catamaran-Medium";
    font-size: 14px;
}

.fab {
    position: fixed !important;
    z-index: 999;
    // top: 887px;
    // left: 1859px;
    // left: 93%;
    // bottom: 5%;
    right: 21px;
    bottom: 16px;
}

.fab-icon {
    color: white;
}
.modal-title{
    font-size: 1.25rem;
    color:#8B0067;
}

.modal-question{
    margin-top: 1rem;
    font-size: 1rem;
}

.modal-option{
    margin-left: 8px;
    margin-right: 8px;
    color: $kuruah-principal-color;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.85rem;
}

.modal-option:hover{
    color: #5f5f5f;
}
