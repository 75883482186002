.no-padding{
    padding: 0!important;
}

.no-margin{
    margin: 0 !important;
}

.flex{
    display: flex!important;
    flex-direction: row!important;
}

.font-muli{
    font-family: "Muli-Regular"!important;
}
.MuiTypography-body1{
    font-family: "Muli-Regular"!important;
}
.space{
    justify-content: space-between;
}
.date-input{
    width: 12.5rem;
    margin-right: 12px!important;
    margin-top: 20px!important;
}

.graph-size{
    margin: 12px;
    height: 28.5rem;
    width: auto;
    overflow-x: scroll;
}


.graph-tamanio{
    
    height: 31rem;

    overflow-x: scroll;
}


.product_img_inventory{
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    height: 16rem;
    width: 16rem;
    border-radius: 50%;
}

.center-items{
    display: grid;
    place-items: center;

    p{
        margin: 0!important;
    }
}

.grey-front{
    color: #9D9D9D;
}

.items-on-the-side{
    
}

.purple-font{
    color: #78065D;
}

.red-font{
    color: red;
}

.items-to-end{
    justify-content: flex-end;
}
.all-balance-info{
    p{
        margin-bottom: 4px;
    }
}
.total-border{
    border-color: #78065D;
    border-width: 3px 0px 0px 0px ;
    border-style: dashed;
}
#inventory-manager{
    margin-top: 32px!important;
    margin-right: 0px!important;
}
.table-inventory-container{
    height: 31rem;
    overflow-y: scroll;
    overflow-x: hidden;
}