.img-last-sale-item{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
}

.sale-item-margin{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.sale-date{
    position: absolute;
    bottom: 0;
    right: 0;
    
}
.date-div{
    position: relative;
}
.center-img{
    display: grid;
    place-items: center;
}
.size-1rem{
font-size: 1rem;

}
.no-margin{
    margin: 0;
    padding: 0;
}
.sale-item-price{
    font-size: 1.5rem;
}

.sale-item-info-container{
    border-bottom: 3px solid #F3F3F3;
    padding-bottom:0.625rem;
}