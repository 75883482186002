.purple-header {
    width: 100%;
    background-color: #78065D;
    height: 100px;
    padding-left: 140px;
    padding-top: 20px;
}

.subscribe-content{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 50px;
}

h5{
    font-weight: 400;
}