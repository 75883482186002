#add-ingredient-button {
  padding: 0 !important;
}

.option-ingredient-info {
  font-family: "Catamaran-Regular";
  padding: 0;
  margin: 0;
}

.name {
  color: #000000;
  font-size: 1rem;
}
.unit {
  color: #9d9d9d;
  font-size: rem;
}
.center-div {
  display: grid;
  place-items: center;
}


.no-margin{
    margin: 0;
    padding: 0;
}
.ingredient-name {
    font-size: 1rem;
}
.ingredient-quantity{
    font-size: 0.875rem;
    color: #9D9D9D;
}

.margin-auto{
    margin-top: auto;
    margin-bottom: auto;
}

.ingredient-icon{
    margin: 0.375rem;
}

.ingredient-item-container{
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
    padding-left: 0.688rem;
}