.import-container{
    width: 50%;
} 

.section-dialog{
    padding: 1rem;
}
.dropzone-style{
    display: grid;
    place-items: center;
    padding: 0.7rem;
    margin-top: 1.5rem;
    border: 3px dashed #ececec;
}

.dropzone-style:hover{
    border: 3px dashed #78065D;
}
.buttons-inventory{
    display: flex;
    justify-content: flex-end;
}

.buttons-whattodo{
    display: flex;
    justify-content: center;
}

.success-inventory{
    margin-left: 1rem;
}
.items-right{
    display: flex;
    justify-content: center;
    margin-right: 1rem;
}
.size-dialog{
    width: auto;
}