.card-img-top.franchise-type {
    width: 100%;
    display: flex;
    align-self: center;
    height: 8rem;
}

div.card.franchise-type {
    width: 98%;
    height: 15rem;
    margin: 0.625rem;
    border-radius: 0.75rem;
    box-shadow: 1px 1px 6px 0 #c1c1c1;
}

.card-title.franchise-type {
    font-size: 1rem;
}


.franchise-type-card-icon {
    left: 10px;
    display: inline;
}

.info-card-down {
    place-items: center;
}