.gridempleado {
  display: grid;
  width: 20rem;
  height: 20rem;
  margin: 0.75rem;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  border: 1px;
}

.card-img-top.round {
  border-radius: 50%;
  width: 11.25rem;
  height: 11.25rem;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.nombreempleado {
  margin-bottom: 3px;
  font-family: "Muli-Light";
  font-size: 20px;
  font-weight: 400;
}

.puntoventaempleado {
  font-family: "Muli-Light";
}

.icontarjeta {
  display: grid;
  place-items: center;
}
