.franchise-item-image{
    object-fit: cover;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    float: left;
    margin-right: 0.5rem;
}

.franchise-item-name{
    font-family: "Muli-Light";
    font-size: 16px;
    color: black;
}

.franchise-item-address {
    font-family: "Muli-Regular";
    font-size: 14px;
    color: #9D9D9D;
}

.franchise-item-container{
    // float: inline-end ;
}