.spinner-size{
  width: 2rem;
  height: 2rem;
}
.range-date-container{
  margin-top: 9px;
}

.table-size{
  height: 57vh;
  overflow: scroll;
}