.lineborder-right {
  border-right: solid #5b6dcd 3px;
}

.providers-container {
  overflow-y: scroll;
  height: 99vh;
  padding-top: 0;
}

.products-provider-container {
  opacity: 1;
  width: 97%;
  padding: 0px 12px;
  font-family: "Catamaran-Regular";
}

.center-text-column {
  display: grid;
  place-items: center;
}

.product-name-justify {
  justify-content: start;
}

.row-product-info {
  height: 67px;
  overflow-y: hidden;
}
.row-product-info p {
  margin: 0 !important;
}
.row-product-simple {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 1px;
  opacity: 1;
  margin: 2px 0px;
  overflow-y: hidden;
}
.principal-full-info {
  background-color: #7b085a;
  color: white;
  padding: 0px 22px;
  background: #78065d 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 8px;
  font-family: "Catamaran-Medium";
}

.principal-full-info h5 {
  margin: 0 !important;
  font-size: 1.15rem !important;
}

.items-in-corners {
  display: flex;
  justify-content: space-between;
  place-items: center;
}

.box-info-stock {
  /* Layout Properties */
  height: 64px;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  margin: 4px 4px 4px 4px;

  display: grid;
  place-items: center;
  p {
    font-size: small;
    font-family: "Muli-Light";
  }

  h6 {
    font-family: "Muli-Regular";
    font-size: 1.2rem !important;
  }
}

div.row-product-info {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.full-info-container {
  margin-bottom: 8px;
  margin-top: 8px;
}

.form-container{
  
  background: #F2F1F9;
    background-color: #FFFFFF;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  margin: 8px;
  padding: 8px;
  width: 525px;
}

.form-filters{
  background: #ffffff 0% 0% no-repeat padding-box;
  
  box-shadow: 1px 1px 2px #00000029;
  border-radius: 8px;
  padding: 12px;
  display: grid;
  place-items: center;
  margin-bottom: 16px;
  justify-content: space-evenly;
  .input{
    margin-right: 12px;
    width: 9.5rem;
    
  }
  .space-12{
   height: 16px;
   color: #78065d;
   font-size: small;
  }
}

.loader-img{
  height: 25%;

}

.grid-cointainder{
  background: var(---ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 2px #00000029;
border-radius: 8px;
opacity: 1;

}

.page-container{
  margin: 1rem;
}