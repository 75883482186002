form {
  .adjust-margin-cat {
    margin-top: 1.5rem;
  }
}
#container-product-form {
  margin: 0;
}

#product-info {
  margin-right: 0.75rem !important;
}

#ingredient-info {
  margin-left: 0.75rem !important;
}

//This is the style of the modal
.modal-box {
  width: 26.75rem;
  height: 9.75rem;
  border-radius: 8px;
  background-color: white;
  border: "2px solid #000";
  top: 50%;
  left: 50%;
  padding: 1.5rem 0px 1.5rem 1.5rem;
}

a {

  text-decoration: none!important;
}
.color-links{
  color: #78065D!important;
}
.color-links:hover{
  color: white!important;
}